import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function PrivacyPolicy() {
  return (
    <Layout>
      <h2 className="text-4xl py-4 font-semibold">プライバシーポリシー</h2>
      <hr className="my-4 md:my-8" />
      <div>
        ごんすな（以下「当方」）は、スマートフォン用アプリケーション（以下「アプリ」）を開発・運用しています。
        当方が提供するアプリにおいて、当方が取得する個人情報を適切に管理・運用するために遵守するべき基本的事項として、
        以下の通りプライバシーポリシー（以下「本プライバシーポリシー」）を定め、個人情報を適正に取り扱います。
      </div>
      <h3 className="text-xl pb-4 pt-8">
        1. 収集する情報
      </h3>
      <div>
        アプリでは、広告配信のためにGoogle AdMob、利用状況解析のためにGoogle Firebase Analyticsを使用する場合がございます。
        広告配信のために広告IDを取得する場合がございます。
      </div>
      <h3 className="text-xl pb-4 pt-8">
        2. 利用目的
      </h3>
      <div>
        当方は、収集した情報をアプリの品質向上のために利用いたします。
        収集した情報を、個人を特定するために使用することはありません。
      </div>
      <h3 className="text-xl pb-4 pt-8">
        3. 個人情報の管理
      </h3>
      <div>
        当方は、お客様の個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、安全対策を実施し個人情報の厳重な管理を行います。
      </div>
      <h3 className="text-xl pb-4 pt-8">
        4. 個人情報の第三者への開示・提供の禁止
      </h3>
      <div>
        当方は、お客様よりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。
        ・お客様の同意がある場合
        ・法令に基づき開示することが必要である場合
      </div>
      <h3 className="text-xl pb-4 pt-8">
        5. 法令・規範の遵守と見直し
      </h3>
      <div>
        当方は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本プライバシーポリシーの内容を適宜見直し、その改善に努めます。
      </div>
      <h3 className="text-xl pb-4 pt-8">
        6. 免責事項
      </h3>
      <div>
        本プライバシーポリシーは、アプリがユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、および不具合が生じないことについて、なんら保証するものではありません。
        当方の都合によりアプリの仕様を変更できます。当方は、本アプリの提供の終了、変更、または利用不能、本アプリの利用によるデータの消失または機械の故障もしくは損傷、その他アプリに関してユーザーが被った損害につき、賠償する責任を一切負わないものとします。
      </div>
      <h3 className="text-xl pb-4 pt-8">
        7. 著作権・知的財産権等
      </h3>
      <div>
        著作権その他一切の権利は、当方または権利を有する第三者に帰属します。
      </div>
      <div className="pb-4 pt-8">
        2022年制定・施行
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="Privacy policy" />
